import i18n from '@/i18n'
import { Catalogs, catalogs } from '@/ifaces/Catalogs.interface'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:lang/legal/:reason',
    name: 'legal',
    component: () => import('../views/LegalView.vue')
  },
  {
    path: '/:lang/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/:lang/agegate',
    name: 'ageguard',
    component: () => import('../views/AgeGateView.vue')
  },
  {
    path: '/:lang/:catalog/survey',
    name: 'survey',
    component: () => import('../views/Quizz.vue')
  },
  {
    path: '/:lang/:catalog/retail',
    name: 'retail',
    component: () => import('../views/Retail.vue')
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('../views/Home.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _, next) => {
  const lang = to.params.lang as keyof typeof i18n.global.messages.value
  const country = to.params.catalog as Catalogs

  let name = to.name
  if (name === 'survey' && to.query.ean) name = 'Result page'

  // GTM START
  const window_M = window as any
  if (window_M && window_M.dataLayer)
    window_M.dataLayer.push({
      "event":  "page_view",
      "country": catalogs[country],
      "language": lang,
      "page_type": name //resultpage
    })
  // GTM END
  if (i18n.global.availableLocales.includes(lang)) {
    i18n.global.locale.value = lang
  } else {
    if (to.path !== '/') return next({ name: 'home', params: {lang: 'en'} })
  }
  next()
})

export { routes };

export default router
