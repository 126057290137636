export default {
  "age-guard": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour accéder à notre site, vous devez être en âge d’acheter et de consommer de l’alcool conformément à la législation en vigueur dans votre pays/région de résidence. Si cette législation n’existe pas dans votre pays/région, vous devez avoir au moins 21 ans."])},
    "form-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays/Region*"])},
    "form-select-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionnez votre pays"])},
    "form-birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année de naissance*"])},
    "form-birthdate-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
    "form-birthdate-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
    "form-birthdate-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
    "legal-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" SpiritsEUROPE, DISCUS"])},
    "cookie-info-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cliquant sur « entrer », vous confirmez avoir l’âge requis dans votre pays pour visiter ce site, vous acceptez"])},
    "cookie-info-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nos Conditions générales d’utilisation"])},
    "cookie-info-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et déclarez avoir lu notre"])},
    "cookie-info-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charte de données personnelles & Cookies"])},
    "legal-info-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ABUS D'ALCOOL EST DANGEREUX POUR LA SANTÉ. À CONSOMMER AVEC MODÉRATION."])},
    "legal-info-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hennessy est en faveur d une consommation modérée de ses produits par le biais de Moët Hennessy, membre des organisations"])},
    "legal-info-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SpiritsEUROPE"])},
    "legal-info-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISCUS"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez l'âge légal pour consommer de l'alcool dans ce pays."])},
    "error-age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas l'âge légal pour consommer de l'alcool dans ce pays."])},
    "error-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un mois, un jour et une année de naissance."])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRER"])}
  },
  "home-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champagne Moment"])},
  "home-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To help you have an interaction with the future!"])},
  "home-action-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clickez pour commencer votre expérience"])},
  "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langage:"])},
  "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A question to test."])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveauté"])},
  "recommendation_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recommendation disponible"])},
  "personalisation completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complétez plus de questions pour personaliser votre résultat"])},
  "return_quizz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner au Quiz"])},
  "collect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collectez le votre"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maintenant"])},
  "ask_guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandez à notre ambassadeur des ventes de vous guider"])},
  "generate_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer un QR"])},
  "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scanner pour votre"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultat"])},
  "share_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagez vos résultats avec notre ambassadeur des ventes"])},
  "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez votre résultat"])},
  "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprenez en plus sur"])},
  "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la boutique"])},
  "Cookie-Policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrer les Cookies"])},
  "Legal-Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes légaux"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oui"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non"])},
  "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
  "$": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40€"])},
  "$$": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["75€"])},
  "$$$": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["150€"])},
  "$$$$": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["450€ and +"])},
  "House_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Maison de"])},
  "product-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["laissez nous vous expliquer"])},
  "visit_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["découvrez la maison"])},
  "perfect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parfait"])},
  "recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECOMMENDATIONS"])},
  "btq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RETOUR AU QUIZ"])},
  "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHOISIR"])},
  "BackHome1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous allez être redirigé vers la page d'accueil"])},
  "BackHome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECOMMENCER ?"])},
  "Stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JE SUIS LÀ"])},
  "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validez"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retour"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suivant"])},
  "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catalogue"])},
  "for-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour vous"])},
  "cookie-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parameter cookie"])},
  "main-catch-phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez votre champagne idéal"])},
  "sub-catch-phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorez, Matchez & Faites sauter le bouchon"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commencez"])},
  "q1_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le but de cette bouteille "])},
  "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu'est-ce qui vous a conduit au Champagne aujourd'hui ?"])},
  "q1r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer une occasion"])},
  "q1r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offrir un cadeau"])},
  "q1r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apporter à une fête"])},
  "q1r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous faire plaisir"])},
  "q1r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accompagner un repas"])},
  "q1r6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour votre collection"])},
  "Mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour marquer une occasion"])},
  "Gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour offrir un cadeau"])},
  "Party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a apporter à une fête"])},
  "Treat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour vous faire plaisir"])},
  "Meal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour accompagner un repas"])},
  "Grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour votre collection"])},
  "q2_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites sauter le bouchon"])},
  "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où faire sauter le bouchon ?"])},
  "q2r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le jardin"])},
  "q2r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur la terrasse"])},
  "q2r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la plage"])},
  "q2r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur le bord de piscine"])},
  "q2r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la maison"])},
  "q2r6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un lieu inattendu"])},
  "Garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans le jardin "])},
  "Terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur la terrasse "])},
  "Beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à la plage "])},
  "Pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur le bord de piscine "])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à la maison "])},
  "Unexpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans ce lieu inattendu "])},
  "Unexpect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans ce lieu inattendu "])},
  "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par ailleur, auriez vous un prix en tête ?"])},
  "q3r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["q3r1"])},
  "q4_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'atmosphère va être"])},
  "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment est l'ambiance "])},
  "q4r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festive & Fun"])},
  "q4r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glamour"])},
  "q4r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cool & Reposante"])},
  "q4r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romantique"])},
  "q4r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formelle"])},
  "q4r6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couronnée de succès"])},
  "q5_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous appréciez les saveurs"])},
  "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle est la plus tentante pour vous ?"])},
  "q5_bis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel couple est le plus tentant ?"])},
  "q5r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fruité & Zesté"])},
  "q5r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riche & Sucré"])},
  "q5r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin & Délicat"])},
  "q5r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grillé & Épicé"])},
  "q6_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous vous attendez à entendre"])},
  "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu'écoutons-nous ?"])},
  "q6r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hip-hop & Rap"])},
  "q6r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blues & Jazz"])},
  "q6r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop & Rock"])},
  "q6r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classique & Orchestral"])},
  "q6r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chill & Deephouse"])},
  "q6r6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electro & Funk"])},
  "q6r7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditionnel & Culturel"])},
  "q7_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette bouteille est pour"])},
  "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien vous rejoindrons ?"])},
  "q7r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's my moment"])},
  "q7r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 à  4"])},
  "q7r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 à 9"])},
  "q7r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 10"])}
}