export default {
  "age-guard": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access our site, you must be of legal age to purchase and consume alcohol in accordance with the legislation in force in your country/region of residence. If this legislation does not exist in your country/region, you must be at least 21 years old."])},
    "form-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country/Region*"])},
    "form-select-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select country"])},
    "form-birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthdate*"])},
    "form-birthdate-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select day"])},
    "form-birthdate-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select month"])},
    "form-birthdate-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select year"])},
    "cookie-info-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking the enter button, you confirm that you are the required age of your country to visit our website, you accept"])},
    "cookie-info-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "cookie-info-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and you declare that you have read our"])},
    "cookie-info-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy & Cookies notice"])},
    "legal-info-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALCOHOL ABUSE IS DANGEROUS FOR HEALTH. TO CONSUME WITH MODERATION."])},
    "legal-info-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Hennessy is in favor of moderate consumption of its products through Moët Hennessy, member of the organizations"])},
    "legal-info-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SpiritsEUROPE"])},
    "legal-info-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISCUS"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are of legal drinking age in this country."])},
    "error-age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are under the legal drinking age in this country."])},
    "error-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a month, day and/or year of birth."])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTER"])}
  },
  "home-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champagne Moment"])},
  "home-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To help you have an interaction with the future!"])},
  "home-action-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click anywhere to begin your experience"])},
  "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language:"])},
  "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A question to test."])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
  "recommendation_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recommendation available"])},
  "personalisation completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete more questions to personalize your results"])},
  "return_quizz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Quiz"])},
  "collect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collect yours"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["now"])},
  "ask_guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask the sales ambassador to guide you to your ideal champagne."])},
  "generate_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate QR"])},
  "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scan for your"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result"])},
  "share_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your results with our Sales Ambassador"])},
  "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover your result"])},
  "learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about"])},
  "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop now"])},
  "Cookie-Policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Settings"])},
  "Legal-Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Terms"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
  "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
  "$": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$40"])},
  "$$": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$75"])},
  "$$$": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$150"])},
  "$$$$": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$450 and +"])},
  "House_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The House of"])},
  "product-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["let us explain"])},
  "visit_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visit the house"])},
  "perfect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect"])},
  "recommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECOMMENDATIONS"])},
  "btq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACK TO QUIZ"])},
  "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHOOSE"])},
  "BackHome1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are being returned to the homepage"])},
  "BackHome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["START AGAIN?"])},
  "Stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'M STILL HERE"])},
  "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validate"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["previous"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
  "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catalog"])},
  "for-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for you"])},
  "cookie-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cookie settings"])},
  "main-catch-phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your ideal Champagne"])},
  "sub-catch-phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore, Match & Pop the cork"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
  "q1_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The purpose of this bottle "])},
  "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What led you to Champagne today?"])},
  "q1r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark an occasion "])},
  "q1r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give as a gift"])},
  "q1r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring the party"])},
  "q1r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treat yourself"])},
  "q1r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair with a meal"])},
  "q1r6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grow your collection"])},
  "Mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to mark an occasion "])},
  "Gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to give as a gift"])},
  "Party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to bring to the party"])},
  "Treat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to treat yourself"])},
  "Meal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to pair with a meal"])},
  "Grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to grow your Collection"])},
  "q2_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will pop the cork"])},
  "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are you going to pop the cork?"])},
  "q2r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the garden"])},
  "q2r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the terrace"])},
  "q2r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the beach"])},
  "q2r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the poolside"])},
  "q2r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At home"])},
  "q2r6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somewhere unexpected"])},
  "Garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in the garden"])},
  "Terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at the terrace"])},
  "Beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at the beach"])},
  "Pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at the poolside"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at home"])},
  "Unexpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in this unexpected place"])},
  "Unexpect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in this unexpected place"])},
  "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By the way, do you have a price in mind?"])},
  "q3r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "q4_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The atmosphere is going to be"])},
  "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How is the vibe"])},
  "q4r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festive & Fun"])},
  "q4r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glamorous"])},
  "q4r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cool & Relaxed"])},
  "q4r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romantic"])},
  "q4r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formal"])},
  "q4r6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celebratory"])},
  "q5_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You enjoy flavors that are"])},
  "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which is the most tempting to you?"])},
  "q5_bis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which pairing is the most tempting?"])},
  "q5r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fruity & Zesty"])},
  "q5r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rich & Sweet"])},
  "q5r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine & Delicate"])},
  "q5r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toasted & Spiced"])},
  "q6_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You expect to hear"])},
  "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you listening to?"])},
  "q6r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hip-hop & Rap"])},
  "q6r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blues & Jazz"])},
  "q6r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop & Rock"])},
  "q6r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classical & Orchestral"])},
  "q6r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chill & Deephouse"])},
  "q6r6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electro & Funk"])},
  "q6r7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditional & Cultural"])},
  "q7_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This bottle is for"])},
  "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many will join you?"])},
  "q7r1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's my moment"])},
  "q7r2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 to 4"])},
  "q7r3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 to 9"])},
  "q7r4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 and more"])}
}