import { createStore } from 'vuex'
// ifaces 
import { Question } from '@/ifaces/Question.interface'
import { Product } from '@/ifaces/Product.interface'

export default createStore({
  state: {
    questionNode: [] as Question[],
    product: null as null | Product,
    products: [] as Product[],
    dataFile: undefined as string | undefined,
    ageValid: false,
  },
  getters: {
    questionNode: state => state.questionNode,
    products: state => state.products,
    product: state => state.product,
    dataFile: state => state.dataFile,
    ageValid: state => state.ageValid,
  },
  mutations: {
    setQuestionNode(state, payload: Question[]) {
      state.questionNode = payload;
    },
    setProduct(state, payload:  null | Product) {
      state.product = payload;
    },
    setProducts(state, payload: Product[]) {
      state.products = payload;
    },
    setFile(state, payload: string | undefined) {
      state.dataFile = payload;
    },
    setAgeValid(state, payload: boolean) {
      state.ageValid = payload;
    },
  },
  actions: {
    setQuestionNode: (context, payload) => context.commit('setQuestionNode', payload),
    getQuestionNode: (context) => context.commit('questionNode'),
    setProduct: (context, payload) => context.commit('setProduct', payload),
    getProduct: (context) => context.commit('product'),
    setProducts: (context, payload) => context.commit('setProducts', payload),
    getProducts: (context) => context.commit('products'),
    setFile: (context, payload) => context.commit('setFile', payload),
    getFile: (context) => context.commit('dataFile'),
    setAgeValid: (context, payload) => context.commit('setAgeValid', payload),
    getAgeValid: (context) => context.commit('ageValid'),
  },
  modules: {
  },
})
