import { TrackerVue, INTERFACES, FETCH_API_TYPES, APP_TYPES } from '@b-feeds/tracker';
import { Capacitor } from '@capacitor/core';

let type = ""

if (Capacitor.getPlatform() === 'web' && !Capacitor.isNativePlatform()) type = 'desktop';
else type = "mobile";

if (window.matchMedia('(display-mode: standalone)').matches) type = "pwa"

const appTypes =  new Map();

appTypes.set('desktop', APP_TYPES.main);
appTypes.set('mobile', APP_TYPES.app);
appTypes.set('pwa', APP_TYPES.POSApp);

const prod = window.location.host !== 'destinationchampagne.com'

const tracker = TrackerVue(INTERFACES.GA_INTERFACE, {
  GA_TRACKING_ID: prod ? "G-P2VTTCP7T8" : "G-Q94XQ097Z8",
  GA_API_SECRET: prod ? "FryvWrLRTJuiD1N7hnSkxw": "O8RG5BcwQoSjr5K0oodhjw",
  appType: appTypes.get(type),
  debug: !prod,
  fetchApiType: FETCH_API_TYPES.AXIOS,
});

export default tracker;